import React, { Component } from "react";
import './Stock.css';
import axios from '../../../axios';
import Cookies from 'universal-cookie';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ProductCard from "../EShop/ProductCard/ProductCard";
import ModalProductQuantity from "../../../components/ModalProductQuantity/ModalProductQuantity";
import * as actionCreators from '../../../store/actions/index';
import { Icon, notify, Sidebar, PaddedContainer, Button, ModalBody, Modal, ModalHeader} from "precise-ui/dist/es6";
import ReactGA from 'react-ga';
import check from   '../../../res/images/check.png';
import stockIcon from '../../../res/images/stock_icon.png';

const { Skeleton} = require('precise-ui');

class Stock extends Component {

    timeout = null;
    state = {
        items: [],
        filters : [],
        activeFilters: [],
        showHomePage: true,
        hiddenFilters: [],
        openModalQuantity: false,
        cartProduct: null,
        showFiltersItemSkeleton: true,
        isScrolling: false,
        seeMoreCount: 1,
        searchValue: '',
        searchBarActive: false,
        openStockClosedModal: false,
        stockClosedEndDate: ''
    }

    sortSelection = 0;


    componentDidMount () {

        if(this.props.isPageVisible) {
            if (!this.loadFromSessionStorage()) {
                this.getHomePageStock();
            }
            sessionStorage.removeItem('stock');
            window.addEventListener("scroll", this.onScroll);
            window.scrollTo(0, 0);

           this.getUserInventoryCart();
           this.isStockOpened();
        }
        else {
            this.props.history.push("/home");
        }

    }

    isStockOpened() {

         let params = {
            section: 'STOCK'
        }

        axios.post('/isModuleClosed', JSON.stringify(params), {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        )
        .then(response => {
            if(response.data) {
                if(response.data.payload.isClosed){
                    this.setState({openStockClosedModal: true, stockClosedEndDate: response.data.payload.dateEnd});
                }

            }
        })
        .catch(err => {
            console.log(err);
        });

    }

    getUserInventoryCart = () => {
        if (this.props.user && this.props.user.accountType == 'Account' && this.props.user.zeissId) {
            axios.get('/getUserInventoryCart/'+this.props.user.zeissId+'/'+this.props.user.priceList)
            .then(response => {
                if (response.data.type === "success") {
                    this.props.updateInventoryCart(response.data.payload);
                } else {
                    console.log(response.data.error);
                }
            })
            .catch(error => {
                console.log(error);
            })
        }
    }

    getHomePageStock() {

        let seeMoreCount = {
            seeMoreCount: this.state.seeMoreCount,
            sapCode: this.props.authUser.sapCode
        }

        axios.post('/getHomeStock', JSON.stringify(seeMoreCount),{
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(response => {
            if (response.data.type === "success") {

                this.setState(() => ({
                    filters: response.data.payload.Filters,
                    items: response.data.payload.Items,
                    activeFilters: this.getActiveFilters(response.data.payload.Filters),
                    showHomePage: true,
                    showFiltersItemSkeleton: false
                }))
            }


        })
        .catch(err => {
            console.log(err);
        });

    }

    componentWillUnmount() {
        sessionStorage.setItem('stock', JSON.stringify(this.state));
        window.removeEventListener("scroll", this.onScroll);
    }

    onScroll = () => {
        this.setState({ isScrolling: true });
    };

    loadFromSessionStorage = () => {
        const routeState = this.props.location.state;
        if (routeState != null && (routeState.from.indexOf('/product') !== -1 || routeState.from.indexOf('/inventory-cart') !== -1)) {
            const prevState = sessionStorage.getItem('stock');
            if (prevState) {
                this.setState(JSON.parse(prevState));
                return true;
            } else return false;
        } else {
            return false;
        }
    }

    getActiveFilters(filters) {
        let arr = [];
        filters.forEach(element => {
            let filterCatObj = { category: element.FilterCategory, values: [], sortSelection: 999};
            arr.push(filterCatObj);
        });
        let joined = this.state.activeFilters.concat(arr);

        return joined;
    }

    handleChangeFilter(filterCategory, filterId, filterValue) {


        this.sortSelection = this.sortSelection + 1;
        let fCategory = this.state.activeFilters.filter(e => e.category === filterCategory)[0];
        if( fCategory.sortSelection == 999 ) {
            fCategory.sortSelection = this.sortSelection;
            fCategory.values.push({code: filterValue, value: filterId});
        }
        else if(fCategory.category == 'Coating Type' ||fCategory.category == 'Coating Color') {
            let fValue = fCategory.values.filter(e => e.value === filterId);
            if(fValue.length > 0) {
                let index = fCategory.values.indexOf(fValue[0]);
                fCategory.values.splice(index, 1);
            }
            else {
                fCategory.values.push({code: filterValue, value: filterId});
            }
        }
        else {
            fCategory.sortSelection = 999;
            fCategory.values.pop();
        }

        this.getFiltersAndItems();
    }

    getActiveFiltersCount() {
        let activeFiltersCount = 0;
        this.state.activeFilters.forEach(element => {
            if(element.values.length > 0 ){
                activeFiltersCount++;
            }
        });

        return activeFiltersCount;
    }

    getFiltersAndItems() {

        let activeFiltersCount = this.getActiveFiltersCount();

        this.setState(() => ({
            showFiltersItemSkeleton: true
        }))

         if(activeFiltersCount == 0 && this.state.searchValue == '') {
             this.getHomePageStock();
         }
         else {

            let activeFilters = {
                filters: this.state.activeFilters,
                seeMoreCount: this.state.seeMoreCount,
                searchValue: this.state.searchValue,
                sapCode: this.props.authUser.sapCode
            }

            axios.post('/getFiltersAndProductsStock', JSON.stringify(activeFilters),{
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(response => {

                if (response.data.type === "success") {
                    this.setState(() => ({
                        ...this.state,
                        items: response.data.payload.Items,
                        filters: response.data.payload.Filters,
                        showHomePage: false,
                        showFiltersItemSkeleton: false
                    }))

                    window.scrollTo(0, 0);
                }
                else {
                    this.getHomePageStock();
                }

            })
            .catch(err => {
            });
         }
     }

    checkedFilter(item, filtrValue){
        let filterValues = this.state.activeFilters.filter(e => e.category === item.FilterCategory)[0];
        if(filterValues.values.length > 0 ) {
            return filterValues.values.find((f) => f.value === filtrValue)
        }
        return false;
    }

    productSelectedHandler = product => {

        //ReactGA.pageview("/product/"+product.ID);

        this.props.history.push("/product/"+product.ID, { from: this.props.location.pathname });

    }

    renderFilters(item) {
        if ( item.FilterCategory === 'Lens Color') {
            return <div className={this.isHiddenFilter(item) ? 'hidden filter-item-container-color' : 'filter-item-container-color'}>
            {
                item.Values.map((filterItem) => (
                    <div className="lens-color-container tooltip-on-hover" >
                        <div className="filter-item filter-item-color " key={filterItem.ID} style={{ backgroundColor: filterItem.Value }} onClick={ () => this.handleChangeFilter(item.FilterCategory, filterItem.ID, filterItem.Extra)}>
                            {
                                this.checkedFilter(item, filterItem.ID) ?
                                <img src={check} className="selected-color-filter"/>
                                :
                                null
                            }
                        </div>
                        <div className="tooltip">{filterItem.Extra} </div>
                    </div>
                ))
            }
            </div>
        }
        else if( item.FilterCategory === 'Coating Color') {
            return <div className={this.isHiddenFilter(item) ? 'hidden filter-item-container-color' : 'filter-item-container-color'}>
            {
                    item.Values.map((filterItem) => (
                        <div className="lens-color-container tooltip-on-hover" >
                            <div className="filter-item f-item-coating-color" key={filterItem.ID}
                                style={{ background: "linear-gradient(90deg, " + filterItem.Extra.PrimaryHexCode + " 25%, " + filterItem.Extra.SecondaryHexCode + " 50%, " + filterItem.Extra.PrimaryHexCode + " 75%"}} onClick={ () => this.handleChangeFilter(item.FilterCategory, filterItem.ID, filterItem.Value)}>
                            {
                                this.checkedFilter(item, filterItem.ID) ?
                                <img src={check} className="selected-color-filter"/>
                                :
                                null
                            }
                            </div>
                            <div className="tooltip">{filterItem.Value} </div>
                        </div>
                    ))
            }
            </div>
        }
        else {
            return <div className={this.isHiddenFilter(item) ? 'hidden filter-item-container' : 'filter-item-container'}>
            {
                    item.Values.map((filterItem) => (
                        <div className="filter-item" key={filterItem.ID}>
                            <label className="filter-checkbox-label">
                                <input type="checkbox" value={filterItem.ID} checked={this.checkedFilter(item, filterItem.ID)}  onChange={ () => this.handleChangeFilter(item.FilterCategory, filterItem.ID, filterItem.Value)} name="produktart" className="filter-checkbox" />
                                <span className="btn-checkbox-style">&nbsp;</span>
                                <span className="checkbox-label">{filterItem.Value} </span>

                            </label>
                        </div>
                    ))
            }
            </div>
        }

    }

    isHiddenFilter(item){
        let hiddenFilters = this.state.hiddenFilters;
        let hiddenFilterIndex = hiddenFilters.findIndex(e => e === item.FilterCategory);
        if(hiddenFilterIndex >= 0 ) {
            return true;
        }
        else {
            return false;
        }
    }

    handleHideFilter(item){
        let hiddenFilters = this.state.hiddenFilters;
        let hiddenFilterIndex = hiddenFilters.findIndex(e => e === item.FilterCategory);
        if(hiddenFilterIndex >= 0 ) {
            hiddenFilters.splice(hiddenFilterIndex, 1);
        }
        else {
            hiddenFilters.push(item.FilterCategory);
        }

        this.setState(() => ({
            hiddenFilters: hiddenFilters
        }))
    }

    cartButtonHandler = (event, product) => {
        let cartProduct = Object.assign({Quantity: product.OrderMinQty}, product);
        this.setState({
            ...this.state,
            cartProduct: cartProduct,
            openModalQuantity: true
        })
    }

    toggleProductModal = () => {
        this.setState({
            ...this.state,
            cartProduct: null,
            openModalQuantity: !this.state.openModalQuantity
        });
    }

    changeProductQuantityHandler = event => {
        let product = Object.assign({}, this.state.cartProduct);
        product.Quantity = +event.target.value;
        this.setState({
            ...this.state,
            cartProduct: product
        });
    }

    addToCartHandler = () => {
        try {
            this.props.onAddToCart(this.state.cartProduct, this.props.authUser.zeissId);
            notify.success({ content: "Product has been added to your inventory cart.", options: { position: 'top-right', autoClose: 3000 } });
            this.toggleProductModal();
        } catch (e) {
            notify.error({ content: e, options: { position: 'top-right', autoClose: 3000 } });
        }
    }

    backToTop = () => {
        window.scrollTo(0, 0);

        clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
          this.setState({ isScrolling: false });
        }, 300);

    }

    renderSkeletonFilters() {
        return <div className="skeleton-catalogue">
            <div className="skeleton-filters">
                <div className="filter-block">
                    <p className="filter-title"> Lens Color </p>
                    <div className="sk-filter-cat-color">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                        <Skeleton width={40} height={40} />
                    </div>
                </div>
                <div className="filter-block">
                    <p className="filter-title"> Color Type </p>
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>
                </div>
                <div className="filter-block">
                    <p className="filter-title"> Filter Category </p>
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>
                    <div className="skeleton-filter-cat">
                        <Skeleton width={40} height={40} />
                        <Skeleton width={160} height={40} />
                    </div>
                </div>
            </div>

        </div>
    }

    renderSkeletonItems() {
        return <div className="skeleton-items">
            <div className="products-container">
                <div className="product-single-block">
                    <div className="product-block-image">
                        <Skeleton width="130px" height="130px" isCircle />
                    </div>
                    <div className="product-block-details skeleton-item">
                        <Skeleton width="100%" height="100%" />
                    </div>
                </div>

                <div className="product-single-block">
                    <div className="product-block-image">
                        <Skeleton width="130px" height="130px" isCircle />
                    </div>
                    <div className="product-block-details skeleton-item">
                        <Skeleton width="100%" height="100%" />
                    </div>
                </div>

                <div className="product-single-block">
                    <div className="product-block-image">
                        <Skeleton width="130px" height="130px" isCircle />
                    </div>
                    <div className="product-block-details skeleton-item">
                        <Skeleton width="100%" height="100%" />
                    </div>
                </div>


                <div className="product-single-block">
                    <div className="product-block-image">
                        <Skeleton width="130px" height="130px" isCircle />
                    </div>
                    <div className="product-block-details skeleton-item">
                        <Skeleton width="100%" height="100%" />
                    </div>
                </div>


                <div className="product-single-block">
                    <div className="product-block-image">
                        <Skeleton width="130px" height="130px" isCircle />
                    </div>
                    <div className="product-block-details skeleton-item">
                        <Skeleton width="100%" height="100%" />
                    </div>
                </div>

                <div className="product-single-block">
                    <div className="product-block-image">
                        <Skeleton width="130px" height="130px" isCircle />
                    </div>
                    <div className="product-block-details skeleton-item">
                        <Skeleton width="100%" height="100%" />
                    </div>
                </div>
            </div>
        </div>
    }

    renderFilterMenu = displayClasses => {
        const classes = "filter-menu " + displayClasses;
        return (
            <div className={classes}>
                {
                    this.state.showFiltersItemSkeleton ?
                        this.renderSkeletonFilters()
                    :
                    <div>
                        {
                            this.state.filters.map((item) => (
                                    item.Values.length > 0 ?
                                    <div className={item.FilterCategory === 'Collection' ? "filter-block filter-inverted" : "filter-block" }>
                                    <p className={this.isHiddenFilter(item) ? ' filter-title hidden-filter' : 'filter-title'} onClick={ () => { this.handleHideFilter(item) }}>
                                        {
                                            item.FilterCategory === 'Collection' ?
                                            <Icon name="Star" size={1} style={{margin: "0 0.4rem 0 0.2rem"}}/>
                                            : null
                                        }
                                        {item.FilterCategory}
                                    </p>
                                    {
                                        this.renderFilters(item)
                                    }
                                    </div>
                                    :
                                    null
                            ))
                        }
                    </div>
                }
            </div>
        );
    }

    renderSidebar = () => (
        <Sidebar
            position="left"
            activator=
                {
                    <div class="d-block d-md-none filter-sidebar-activator">
                        Filters
                    </div>
                }
        >
            <PaddedContainer gutter="small">
                <div style={{marginTop: '15px'}}>
                    <div>
                        {this.renderActiveFilters()}
                    </div>
                    {this.renderFilterMenu()}
                </div>
            </PaddedContainer>
        </Sidebar>
    );

    renderProductCard = (subitem) => {
        const inCart = this.checkProductInCart(subitem);
        return (
            <ProductCard
                key={subitem.ID}
                product={subitem}
                clicked={() => this.productSelectedHandler(subitem)}
                cartClicked={(event) => this.cartButtonHandler(event, subitem)}
                inCart={inCart}
                type='Stock' />
        );
    }

    checkProductInCart = (item) => {
        const cartProduct = this.props.storedCart.find(product => product.ID === item.ID);
        return cartProduct !== undefined;
    }


    renderActiveFilters() {
        return <div>
            {
                this.state.activeFilters.map((activeFilter) => (
                    activeFilter.sortSelection != 999 ?
                        activeFilter.values.map((activeFilterItem) => (
                            <p className="selected-filter-block" onClick={ () => this.removeSelectedFilter(activeFilter, activeFilterItem)}>
                                {activeFilter.category}: {activeFilterItem.code}
                            </p>
                        ))
                    :
                    null
                ))

            }
            </div>
    }

    handleClickSeeMore() {
        let count =  this.state.seeMoreCount + 1;

        this.setState(({
            seeMoreCount: count
        }), this.getFiltersAndItems);
    }

    removeSelectedFilter (activeFilter, activeFilterItem) {
        this.handleChangeFilter(activeFilter.category,activeFilterItem.value, activeFilterItem.code);
    }

    renderStockProductsCard (items) {

        let showSeeMoreButton = false;
        if(this.state.seeMoreCount == 1 && items.length == 29) {
            showSeeMoreButton = true;
        }
        else if(this.state.seeMoreCount > 1) {
            let currentCount = this.state.seeMoreCount;
            let fairItems = currentCount * 29;
            if(fairItems ==  items.length){
                showSeeMoreButton = true;
            }
        }

        return <div className="products-container">
            {
                items.map((subitem) => (
                    this.renderProductCard(subitem)
                ))
            }
            {
                showSeeMoreButton ?
                <div className="see-more-container">
                    <Button className="see-more-button" buttonStyle="primary" style={{width: '120px' }}  onClick={ () => { this.handleClickSeeMore(null) }} >See more </Button>
                </div>
                :
                null
            }

        </div>
    }

    searchFieldHandler = (e) => {
        if (e.key === 'Enter') {
            e.target.blur();
            this.startSearch();
        }
    }

    searchFieldOnChange = event => {
        this.setState({
            searchValue: event.target.value
        });
    };

    startSearch = () => {
        this.setState(() => ({
            searchBarActive: true
        }), () => {this.getFiltersAndItems()});

    }

    clearSearch = () => {
        this.setState(() => ({
            searchBarActive: false,
            searchValue: ''
        }), () => {this.getFiltersAndItems()});
    }

    handleCloseStockLocked() {
        this.setState({openStokClosedModal: false});
        this.props.history.push("/home");
    }

    render () {
        return (
            <div className="page-container">
                <div className="header-gray-line"> </div>
                <div className="eshop-container">
                    <div className="catalogue-container relative">
                        <div className="stock-search">
                            <input className="search-input" value={this.state.searchValue} placeholder="Search for color code or coating" onChange={this.searchFieldOnChange} onKeyDown={this.searchFieldHandler}></input>
                            {
                                this.state.searchBarActive ?
                                <span class="icon-close-delete search-input-icon" onClick={this.clearSearch}></span>
                                :
                                <span class="icon-search search-input-icon" onClick={this.startSearch}></span>
                            }
                        </div>
                        {
                            this.state.isScrolling ?
                            <div className="arrow-up" onClick={ () => this.backToTop()}>
                                <Icon name='KeyboardArrowUp' style={{color: '#fff'}}  size={3} />
                            </div>
                            :
                            null
                        }

                        {this.renderFilterMenu('d-none d-md-flex')}

                        {
                            this.state.showHomePage ?
                                <div className="catalogue-content">
                                    {this.renderSidebar()}
                                    {
                                        this.state.showFiltersItemSkeleton ?
                                        this.renderSkeletonItems()
                                        :


                                        <div style={{marginBottom: '30px'}}>
                                            <div className="catalogue-right-container">
                                                <h3> Stock </h3>
                                                {
                                                    this.renderStockProductsCard(this.state.items)
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            :
                            <div className="catalogue-content">
                                {this.renderSidebar()}
                                <div className="d-none d-md-block m-t-25">
                                    <div className="d-flex">
                                        {this.renderActiveFilters()}
                                    </div>
                                </div>
                                <div className="catalogue-right-container">
                                    {
                                        this.state.showFiltersItemSkeleton ?
                                            this.renderSkeletonItems()
                                        :
                                        <div>
                                            <div className="catalogue-right-container">
                                                <h3> Stock </h3>
                                                <div className="products-container">
                                                    {
                                                        this.state.items.map((product) => (
                                                            this.renderProductCard(product)
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <ModalProductQuantity
                    open={this.state.openModalQuantity}
                    toggle={this.toggleProductModal}
                    product={this.state.cartProduct}
                    changeQty={(event) => this.changeProductQuantityHandler(event)}
                    cartLength={this.props.storedCart.length}
                    addToCart={this.addToCartHandler}
                    icon={stockIcon}
                />
                 {/* stock suspended */}
                <Modal open={this.state.openStockClosedModal} onClose={this.handleCloseStockLocked.bind(this)}>
                    <ModalHeader title="Stock module is temporarily suspended"/>
                    <ModalBody>
                        The stock module is temporarily suspended. It will be available again on <b> {this.state.stockClosedEndDate} </b>.
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => {

    let user;
    if(state.auth.user) {
        user = state.auth.user;
    }
    else {
        user = JSON.parse(localStorage.getItem('user'));
    }

    return {
        storedCart: state.stock.inventoryCart,
        authUser: user,
        isPageVisible: checkPageVisibilites(user)
    };

    function checkPageVisibilites(user) {
        if(user) {
            let modules = user.modulesEnabled.split(';');
            if(modules.includes("Stock")) {
                return true;
            } else {
                return false;
            }
        }
        else
            return false;
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onAddToCart: (product, idzeiss) => dispatch(actionCreators.addToInventoryCart(product, idzeiss)),
        updateCart: (items) => dispatch(actionCreators.updateCart(items))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Stock));